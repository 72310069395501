.spinner {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #121227;
    z-index: 2000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        color: rgb(191, 191, 191);
        margin-top: 40px
    }
    svg {
        animation: rhs 2000ms;
        animation-iteration-count:infinite;
    }
}